export const routes = {
    path: 'templates',
    name: 'Templates',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['TEMPL-00'],
    },
    children: [
        {
            path: ':id',
            name: 'Templates_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['TEMPL-02'],
            },
        },
        {
            path: 'inserir',
            name: 'Templates_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['TEMPL-01'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Templates_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['TEMPL-03'],
            },
        },
    ],
};