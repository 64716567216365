const listagensModule = {
    state() {
        return {
            atualizar: false,
        };
    },

    mutations: {
        setAtualizar(state, status) {
            state.atualizar = status;
        },
    },

    actions: {
        setAtualizar({ commit }, status) {
            commit('setAtualizar', status);
        },
    },

    getters: {
        atualizar(state) {
            return state.atualizar;
        },
    },
};

export default listagensModule;
