import projetosMenu from './projetos/menu';
import credoresMenu from './credores/menu';
import agendamentosMenu from './agendamentos/menu';
import acompanhamentosMenu from './acompanhamentos/menu';

const menu = {
    label: 'Principal',
    icon: 'pi pi-fw pi-dollar',
    rules: [...projetosMenu.rules, ...credoresMenu.rules, ...agendamentosMenu.rules, ...acompanhamentosMenu.rules],
    items: [projetosMenu, credoresMenu, agendamentosMenu, acompanhamentosMenu],
};

export default menu;
