<template>
    <Button :label="btnTitle" icon="pi pi-arrow-circle-left" class="p-ml-2 p-button-warning" @click="voltar()" />
</template>

<script>
export default {
    name: 'BtnVoltar',
    props: {
        btnTitle: {
            type: String,
            default: 'Voltar',
        },

        route: {
            type: Object,
            default: () => null,
        },

        back: {
            type: Function,
            default: () => null,
        },

        history: {
            type: Boolean,
            default: () => false,
        },
    },

    methods: {
        voltar() {
            if (this.history) {
                this.$router.go(-1);
            } else if (this.route) {
                this.$router.push(this.route);
            } else {
                this.back();
            }

            // if (this.route !== null) {

            //     console.log('Entrou na rota');
            // } else if (this.back !== null) {
            //     this.back();
            //     console.log(this.back);
            //     console.log('Entrou na função');
            // } else {

            //     console.log('Entrou no history');
            // }
        },
    },
};
</script>
