import { routes as templatesRotas } from './templates/rotas.js';
import { routes as parametrosRotas } from './parametros/rotas.js';

const routes = {
    path: 'administracao',
    name: 'Administracao',
    component: () => import('./Administracao.vue'),
    children: [
        templatesRotas,
        parametrosRotas,
    ],
    meta: {
        rules: [
            ...templatesRotas.meta.rules,
            ...parametrosRotas.meta.rules,
        ],
    },
};

export default routes;
