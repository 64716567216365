import usuariosRotas from './usuarios/rotas.js';
import perfisAcessoRotas from './perfisacesso/rotas.js';
import regrasAcessoRotas from './regrasacesso/rotas.js';
import googleRotas from './google/rotas.js';

const routes = {
    path: 'controleacesso',
    name: 'ControleAcesso',
    component: () => import('./ControleAcesso.vue'),
    children: [usuariosRotas, perfisAcessoRotas, regrasAcessoRotas, googleRotas],
    meta: {
        rules: [...usuariosRotas.meta.rules, ...perfisAcessoRotas.meta.rules, ...regrasAcessoRotas.meta.rules, ...googleRotas.meta.rules],
    },
};

export default routes;
