<template>
    <div class="text-color p-mr-2 p-d-flex p-ai-center p-text-center">
        <countdown :endDateTime="dataFinal" @finished="endCallBack()">
            <template v-slot:default="{ countdown }">
                <span>
                    <small>Tempo de Sessão</small>
                </span>
                <br />
                <strong>
                    <span>{{ countdown.remainingHours.value.toString().padStart(2, '0') }}</span>
                    <i>:</i>
                    <span>{{ countdown.remainingMinutes.value.toString().padStart(2, '0') }}</span>
                    <i>:</i>
                    <span>{{ countdown.remainingSeconds.value.toString().padStart(2, '0') }}</span>
                </strong>
            </template>
            <!-- <template slot="finish">
                <span>
                    <small>Sessão Finalizada</small>
                </span>
            </template> -->
        </countdown>
    </div>
</template>

<script>
import VueCountdown from '@smithalan2/vue-countdown';

export default {
    name: 'TempoSessao',

    data() {
        return {
            dataFinal: null,
        };
    },

    methods: {
        endCallBack() {
            this.$store.dispatch('logout');
        },
    },

    created() {
        this.dataFinal = new Date(this.$store.getters.getExpirationDate);
    },
    components: {
        countdown: VueCountdown,
    },
};
</script>

<style scoped>
.text-color {
    color: white;
}
</style>
