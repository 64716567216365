<template>
    <div class="loadingfullwrapper">
        <div class="loadingfull">
            <div class="spinnerfull">
                <font-awesome-layers full-width class="fa-6x">
                    <font-awesome-icon
                        class="gray8 p-mb-5 spinnerCollor"
                        icon="circle-notch"
                        spin
                    />
                    <!-- <font-awesome-layers-text
                        class="gray8 p-mt-5"
                        transform="down-2 shrink-8"
                        value="Processando..."
                    /> -->
                </font-awesome-layers>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // props: {
    //     blocked: {
    //         type: Boolean,
    //         default: false
    //     }
    // }
};
</script>

<style>
.loadingfullwrapper {
    position: fixed;
    z-index: 10000;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loadingfullwrapper:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.loadingfull {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
}

.spinnerfull {
    margin: auto;
    z-index: 10001;
}

.spinnerCollor {
    /* color: #0099ff; */
    color: #0772b3;
}
</style>
