import usuariosMenu from './usuarios/menu';
import perfisMenu from './perfisacesso/menu';
import regrasMenu from './regrasacesso/menu';
import googleMenu from './google/menu';

const menu = {
    label: 'Controle de Acesso',
    icon: 'pi pi-fw pi-id-card',
    rules: [...usuariosMenu.rules, ...perfisMenu.rules, ...regrasMenu.rules, ...googleMenu.rules],
    items: [usuariosMenu, perfisMenu, regrasMenu, googleMenu],
};

export default menu;
