const routes = {
    path: 'credores',
    name: 'Credores',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['CREDORES-00'],
    },
    children: [
        {
            path: ':projetoId/:id',
            name: 'Credores_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['CREDORES-02'],
            },
        },
        {
            path: ':projetoId/inserir',
            name: 'Credores_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['CREDORES-01'],
            },
        },
        {
            path: ':projetoId/:id/atualizar',
            name: 'Credores_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['CREDORES-03'],
            },
        },
    ],
};

export default routes;
