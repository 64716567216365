const routes = {
    path: 'projetos',
    name: 'Projetos',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['PROJETOS-00'],
    },
    children: [
        {
            path: ':id',
            name: 'Projetos_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['PROJETOS-02'],
            },
            children: [
                {
                    path: 'importarplanilha',
                    name: 'Projetos_ImportarPlanilha',
                    component: () => import('./planilhas/Planilha.vue'),
                    meta: {
                        rules: ['PROJETOS-01'],
                    },
                },
            ]
        },
        {
            path: 'inserir',
            name: 'Projetos_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['PROJETOS-01'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Projetos_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['PROJETOS-03'],
            },
        },
    ],
};

export default routes;
