<template>
    <div class="layout-footer">
        <div>
            <!-- <img src="assets/layout/images/logo-roma.svg" alt="roma-layout"/>
			<p>Premium PrimeVue Application Template</p> -->
            <p>
                NEOFASE <strong>{{ versao }}</strong>
            </p>
        </div>
        <!-- <div class="footer-icons">
			<button class="p-link">
				<i class="pi pi-home"></i>
			</button>
			<button class="p-link">
				<i class="pi pi-globe"></i>
			</button>
			<button class="p-link">
				<i class="pi pi-envelope"></i>
			</button>
		</div> -->
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
    data() {
        return {
            versao: process.env.VUE_APP_VERSION,
        };
    },
};
</script>

<style scoped></style>
