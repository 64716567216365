const routes = {
    path: 'perfis',
    name: 'Perfis',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['CTRLACS-PA-00'],
    },
    children: [
        {
            path: ':id',
            name: 'Perfis_Detalhar',
            component: () => import('./Detalhar.vue'),
            meta: {
                rules: ['CTRLACS-PA-02'],
            },
        },
        {
            path: 'inserir',
            name: 'Perfis_Inserir',
            component: () => import('./Inserir.vue'),
            meta: {
                rules: ['CTRLACS-PA-01'],
            },
        },
        {
            path: ':id/atualizar',
            name: 'Perfis_Atualizar',
            component: () => import('./Atualizar.vue'),
            meta: {
                rules: ['CTRLACS-PA-03'],
            },
        },
    ],
};

export default routes;
