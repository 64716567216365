import { createRouter, createWebHashHistory } from 'vue-router';

import App from './App.vue';
import { trackRouter } from 'vue-gtag-next';
import store from './store/store';
import { temAcessoView } from './shared/auth/temAcesso';

import controleAcesso from './app/controleacesso/rotas.js';
import administracao from './app/administracao/rotas.js';
import projetosRotas from './app/projetos/rotas';
import credoresRotas from './app/credores/rotas';
import agendamentosRotas from './app/agendamentos/rotas';
import acompanhamentosRotas from './app/acompanhamentos/rotas';

const routes = [
    {
        path: '/',
        name: 'home',
        component: App,
        children: [controleAcesso, administracao, projetosRotas, credoresRotas, agendamentosRotas, acompanhamentosRotas],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./app/auth/Login.vue'),
    },
    {
        path: '/atualizarsenha',
        name: 'atualizarsenha',
        component: () => import('./app/auth/AtualizarSenha.vue'),
    },
    {
        path: '/recuperarsenha',
        name: 'recuperarsenha',
        component: () => import('./app/auth/RecuperarSenha.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue'),
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

trackRouter(router);

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/error', '/atualizarsenha', '/recuperarsenha'];
    const authRequired = !publicPages.includes(to.path);

    if (!authRequired) {
        next();
    } else {
        const loggedIn = store.getters.isAutenticado;
        if (authRequired && !loggedIn) {
            store.dispatch('tryAutoLogin');
            if (store.getters.isAutenticado) {
                if (temAcessoView(to.meta.rules)) {
                    next();
                } else {
                    next('/access');
                }
            } else {
                next('/login');
            }
        } else {
            if (temAcessoView(to.meta.rules)) {
                next();
            } else {
                next('/access');
            }
        }
    }
});

export default router;
