const loadingModule = {
    state() {
        return {
            loading: false,
            numeroRequests: 0
        };
    },

    mutations: {
        setLoading(state, status) {
            state.loading = status;
        },

        addRequest(state) {
            state.numeroRequests++;
            state.loading = state.numeroRequests > 0;
        },

        removeRequest(state) {
            state.numeroRequests--;
            state.loading = state.numeroRequests > 0;
        }
    },

    actions: {
        showLoading({ commit }) {
            commit("setLoading", true);
        },

        hideLoading({ commit }) {
            commit("setLoading", false);
        },

        addRequest({ commit }) {
            commit("addRequest");
        },

        removeRequest({ commit }) {
            commit("removeRequest");
        }
    },

    getters: {
        isLoading(state) {
            return state.loading;
        }
    }
};

export default loadingModule;
