const routes = {
    path: 'agendamentos',
    name: 'Agendamentos',
    component: () => import('./Listagem.vue'),
    meta: {
        rules: ['AGENDAMENTOS-00'],
    }
};

export default routes;
