import { createStore } from 'vuex';
import authModule from './auth';
import loadingModule from './loading';
import listagensModule from './listagens';

const store = createStore({
    modules: {
        auth: authModule,
        loading: loadingModule,
        listagem: listagensModule,
    },
});

export default store;
