const routes = {
    path: 'google',
    name: 'Google',
    component: () => import('./Google.vue'),
    meta: {
        rules: ['CTRLACS-GOOGLE-00'],
    }
};

export default routes;
